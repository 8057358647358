import React from "react"
import styled from "styled-components"
import Downtown from "../../images/downtown.jpg"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Downtown}");
  width: 100%;
  height: 400px;
  background-position: 20%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 180px;
  margin-bottom: 32px;
  background-size: 180px 30px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

class WhereToStayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Where to stay | Sara & Tim" />
        <H1>Where to stay</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <P>
          There is a good selection of different types of accommodation in
          Kelowna for all budgets and tastes, from hotels, motels and Airbnb
          apartments to campsites overlooking the lake.
        </P>
        <P>
          Downtown Kelowna is a lovely place to stay as it's very walkable, with
          many interesting shops and cafes and the Croxford Residence around a
          10 minute drive away.
        </P>
        <P>
          Please let us know if you have any issues with finding suitable
          accommodation. Given that COVID-19 is still circulating, we would
          suggest booking accomodation with a free cancellation policy.
        </P>
      </Layout>
    )
  }
}

export default WhereToStayPage
